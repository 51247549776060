import { Divider, Grid } from "@mui/material";
import SoftBox from "components/SoftBox";
import PageLayout from "pages/layout/page-layout";
import ProductDescription from "pages/user/product-pages/krema-protiv-bora/product-description";
import Reviews from "pages/user/reviews";
import { useDocumentTitle } from "hooks/use-document-title";
import { SERVER_URL_PREFIX } from "api";
import ProductDetails from "pages/user/components/product-details";
import { Tabs, Tab } from "@mui/material";
import { useEffect, useState } from "react";
import { get } from "api";
import { useSoftUIController, setOpenConfigurator } from "context";
import ScrollableProductList from "pages/user/product-pages/components/scrollable-product-list";
import SoftTypography from "components/SoftTypography";
import { addCartItem, updateProduct } from "context";
import { RESOURCES_SERVER_URL_PREFIX } from "api";
import config from "config/config";
import { useMetaTag } from "hooks/use-document-title";
import useGTMEvent from "hooks/use-gtm-event";
import ProductDetailsSkeleton from "pages/user/components/product-details/product-details-skeleton";

const AllInOneSet = () => {
  useDocumentTitle("Set Krema + Maska + Serum | Mama za pet – Kompletna Njega Lica");
  useMetaTag(
    "description",
    "Komplet prirodne kozmetike za lice: krema protiv bora, zeolitna maska i serum za lice. Sve što vam je potrebno za savršenu njegu."
  );
  useMetaTag(
    "keywords",
    "mama za pet set, all-in-one mama za pet set, prirodna kozmetika, krema protiv bora, zeolitna maska za lice, serum za lice, mama za pet, mama za pet prirodni proizvodi, mama za pet kozmetika, mama za pet proizvodi za lice"
  );

  const [tabIndex, setTabIndex] = useState(0);
  const sendEventToGTM = useGTMEvent();

  const handleTabChange = (event, newValue) => {
    setTabIndex(newValue);
  };

  const [controller, dispatch] = useSoftUIController();
  const { selectedCurrency, cartItems } = controller;
  const [isLoadingProducts, setIsLoadingProducts] = useState(false);
  const [products, setProducts] = useState([]);
  const handleConfiguratorOpen = () => setOpenConfigurator(dispatch, true);

  useEffect(() => {
    setIsLoadingProducts(true);
    get(`${SERVER_URL_PREFIX}/api/web/products`)
      .then((response) => response.json())
      .then((data) => {
        setProducts(data);
      })
      .finally(() => {
        setIsLoadingProducts(false);
      });
  }, []);

  const getProductsAddToCardData = (productName, productQuantity) => {
    const product = products.find((p) => p.name === productName);

    if (product) {
      const { id, name, imageLocation, quantity, unitPriceBam, unitPriceEur } = product;
      const price = selectedCurrency?.value === "bam" ? unitPriceBam : unitPriceEur;

      return {
        id: String(id),
        name: name,
        img: `${RESOURCES_SERVER_URL_PREFIX}${imageLocation}`,
        quantity: productQuantity,
        unitPrice: price,
        unitQuantity: quantity,
      };
    }

    return undefined;
  };

  const handleAddToCart = (product, eventSource) => {
    const currentProduct = cartItems.find((item) => item.id === product.id);
    get(
      `${SERVER_URL_PREFIX}/api/web/product/price/calculation?productId=${product.id}&quantity=${currentProduct ? currentProduct.quantity + 1 : product.quantity
      }&currency=${selectedCurrency.value.toUpperCase()}`
    )
      .then((response) => response.json())
      .then((data) => {
        if (!currentProduct) {
          addCartItem(dispatch, {
            ...product,
            realPrice: data.realPrice,
            calculatedPrice: data.calculatedPrice,
          });
          sendEventToGTM("add_to_cart", {
            product_id: product.id,
            product_name: product.name,
            product_category: product.type,
            product_price: data.realPrice,
            product_calculated_price: data.calculatedPrice,
            currency: selectedCurrency.value,
            quantity: 1,
            page_source: "All-In-One Set",
            event_source: eventSource,
          });
        } else {
          const newQuantity = currentProduct.quantity + product.quantity;
          updateProduct(dispatch, {
            ...product,
            realPrice: data.realPrice,
            calculatedPrice: data.calculatedPrice,
            quantity: newQuantity,
          });
          sendEventToGTM("update_cart_quantity", {
            product_id: product.id,
            product_name: product.name,
            product_category: product.type,
            product_price: data.realPrice,
            product_calculated_price: data.calculatedPrice,
            currency: selectedCurrency.value,
            new_quantity: newQuantity,
            page_source: "All-In-One Set",
            event_source: eventSource,
          });
        }
      });
  };

  const handleAdd = (product, eventSource) => {
    setTimeout(handleConfiguratorOpen, 300);
    handleAddToCart(getProductsAddToCardData(product.name, product.quantity), eventSource);
  };

  const getCartItemQuantity = (productName) => {
    const product = cartItems.find((item) => item.name === productName);

    if (product) {
      return product.quantity;
    }

    return 0;
  };

  const getMaxCartQuantity = (productName) => {
    return config.appSettings.maxQuantityPerItem - getCartItemQuantity(productName);
  };

  return (
    <PageLayout sx={{ overflow: "hidden" }}>
      <Divider sx={{ mb: "0" }} />

      {products && products.length > 0 && !isLoadingProducts ? (
        <ProductDetails
          product={products.find((p) => p.id === 4)}
          onAddToCart={(product) => handleAdd(product, "add_to_cart_button")}
          productImages={[
            `${RESOURCES_SERVER_URL_PREFIX}/static/images/products/blue/all-in-one-set-new-square.webp`,
            `${RESOURCES_SERVER_URL_PREFIX}/static/images/products/common/krema-square.webp`,
            `${RESOURCES_SERVER_URL_PREFIX}/static/images/products/common/maska-square.webp`,
            `${RESOURCES_SERVER_URL_PREFIX}/static/images/products/common/serum-square.webp`,
          ]}
          descriptionContentArray={[
            "Sve što vam treba za zdravu, blistavu i mladoliku kožu – u jednom setu!",
            "Mama za pet set za njegu kože savršen je spoj tri moćna proizvoda: kreme protiv bora, zeolitne maske i seruma. Dizajniran je da odgovori na sve potrebe vaše kože – od dubinskog čišćenja i hidratacije, do regeneracije i smanjenja bora.",
            "Prednosti Mamazapet seta: kompletna rutina njege u jednom setu, prirodna formula, prikladan za sve tipove kože, praktičnost, vidljivi rezultati",
          ]}
          maxQuantity={getMaxCartQuantity("All-In-One set")}
        />
      ) : (
        <ProductDetailsSkeleton />
      )}

      <Grid
        container
        width={{ xs: "100%", lg: "100%", xl: "100%" }}
        mt={{ xs: "3rem", lg: "0" }}
        sx={{
          display: "flex",
          alignItems: "flex-start",
          padding: "0rem",
        }}
      >
        <Tabs
          value={tabIndex}
          onChange={handleTabChange}
          centered
          sx={{
            width: "100%",
            mb: "1rem",
            backgroundColor: "white !important",
            borderBottom: "1px solid #e0e0e0",
            borderTop: "1px solid #e0e0e0",
            borderRadius: "0",
            padding: "0",
            "& .MuiTab-root": {
              minHeight: "64px !important",
              color: "black",
              maxWidth: "220px !important",
              "&:hover": {
                color: "#6ac4bb !important",
              },
            },
            "& .Mui-selected": {
              color: "#6ac4bb !important",
              borderBottom: "3px solid #7eddd3",
              borderRadius: "0",
            },
            "& .MuiTabs-indicator": {
              display: "none",
              borderBottom: "1px solid black",
            },
          }}
        >
          <Tab label="O PROIZVODU" sx={{ fontSize: "14px" }} />
          <Tab label="SASTOJCI" sx={{ fontSize: "14px" }} />
          <Tab label="NAČIN UPOTREBE" sx={{ fontSize: "14px" }} />
        </Tabs>

        {tabIndex === 0 && (
          <ProductDescription
            sx={{
              px: "1rem",
              pt: "1rem",
              maxWidth: "900px",
              margin: "0 auto",
            }}
            titleSx={{ pb: "1rem" }}
            title="Daje rezultate već nakon 7 dana!"
            textVariant="body2"
            textArray={[
              "Šta sadrži Mamazapet set?",
              "1. Krema protiv bora",
              "Dubinska hidratacija: Vraća koži elastičnost i spriječava pojavu bora uz pomoć zeolita i 12 biljnih eteričnih ulja.",
              "Regeneracija i zaštita: Sastojci poput smilja, čajevca i argana djeluju na fine linije, akne, ožiljke i neujednačen ten",
              "Koristi se kao dnevna, noćna i krema za područje oko očiju i dekolte.",
              "2. Zeolitna maska",
              "Dubinsko čišćenje i piling: Uklanja toksine, zagađenja i mrtve ćelije kože, ostavljajući kožu svježom i čistom.",
              "Obnova kože: Aktivni sastojci poput smilja i ruže smanjuju akne, ožiljke i upale, čineći kožu blistavom i zdravom.",
              "3. Serum za intenzivnu njegu",
              "Regeneracija ćelija kože: Smanjuje ožiljke, bore i neujednačen ten.",
              "Hidratacija u dubini: hladno cijeđena i biljna eterična ulja osiguravaju optimalnu hidrataciju i zdrav izgled kože.",
              "Miris eteričnih ulja opušta um i doprinosi osjećaju wellnessa, naročito prije spavanja."
            ]}
          />
        )}
        {tabIndex === 1 && (
          <ProductDescription
            sx={{
              px: "1rem",
              pt: "1rem",
              maxWidth: "900px",
              margin: "0 auto",
            }}
            titleSx={{ pb: "1rem" }}
            title="Sastojci"
            textVariant="body2"
            textArray={[
              "Zeolit: Prirodni mineral s moćnim detoksikacijskim svojstvima – dubinski čisti kožu, uklanja toksine i pomaže u regeneraciji.",
              "Hladno cijeđeno ulje pšeničnih klica: Bogato vitaminom E, dubinski hrani kožu i pruža dugotrajnu hidrataciju.",
              "Hladno cijeđeno ulje avokada: Pomaže obnavljanju kože i smanjuje fine linije i bore.",
              "Hladno cijeđeno ulje argana: Obnavlja elastičnost i smanjuje osjećaj suhoće.",
              "Eterično ulje ruža i smilje - Regenerišu kožu, smanjuju crvenilo i vraćaju prirodan sjaj.",
              "Eterično ulje kardamon i tamanu - Pomažu u smanjenju bubuljica i nepravilnosti.",
              "Eterično ulje origano i bor - Čiste pore i pružaju osvježavajući osjećaj.",
              "Kardamon i tamanu: Pomažu u smanjenju bubuljica i nepravilnosti",
              "Ulje čajevca, eukaliptusa i tamanu  djeluju antibakterijski, pomažući u smanjenju akni i upala.",
              "Smilje i ruža ubrzavaju obnavljanje kože, smanjujući ožiljke i tamne mrlje.",
              "Lavanda i ruža: Umiruju osjetljivu kožu, smanjuju crvenilo i njeguju suhu, zrelu i osjetljivu kožu.",
              "Zahvaljujući hranjivim sastojcima bogatim antioksidansima i vitaminima, proizvodi djeluju dubinski kako bi smanjili vidljivost bora i usporili proces starenja kože."
            ]}
          />
        )}
        {tabIndex === 2 && (
          <ProductDescription
            sx={{
              px: "1rem",
              pt: "1rem",
              maxWidth: "900px",
              margin: "0 auto",
            }}
            titleSx={{ pb: "1rem" }}
            title="Način upotrebe"
            textVariant="body2"
            textArray={[
              "1. Krema protiv bora",
              "Ujutru:",
              "Nakon čišćenja lica, nanesi tanki sloj kreme na lice, područje oko očiju i dekolte.",
              "Lagano umasiraj kružnim pokretima dok se potpuno ne upije.",
              "Krema se brzo upija i ne ostavlja masne tragove, što je čini savršenom podlogom za šminku.",
              "Naveče:",
              "Koristi je kao noćnu kremu za dubinsku regeneraciju tokom noći.",
              "2. Zeolitna maska",
              "Jednom do dva puta sedmično uraditi sljedeće:",
              "Nakon čišćenja lica, prije spavanja, nanesi tanak sloj maske na lice i dekolte.",
              "Nježno umasiraj masku kružnim pokretima.",
              "Ostavite masku da djeluje preko noći – ne vidi se na koži i ne ostavlja tragove na posteljini.",
              "Ujutro isperi lice mlakom vodom i nastavi rutinu sa serumom ili kremom.",
              "Za ciljano djelovanje:",
              "Masku možeš nanositi svake večeri na problematična područja poput bubuljica.",
              "3. Serum",
              "Svakodnevno, ujutru i/ili naveče – prilagodi svojoj koži",
              "Nakon čišćenja lica, nanesi nekoliko kapi seruma na lice, vrat i dekolte.",
              "Nježno umasirajte serum vrhovima prstiju.",
              "Serum se upija u roku od nekoliko sekundi.",
              "Dodatni savjet: Za intenzivnu hidrataciju, nanesi serum prije kreme protiv bora kako bi se svi aktivni sastojci bolje apsorbovali."
            ]}
          />
        )}
      </Grid>

      <Divider sx={{ mb: "0", mt: "3rem" }} />
      <SoftBox
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        textAlign="center"
        pt="1rem"
        px={{ xs: "1rem", md: "0" }}
      >
        <SoftTypography variant="h4" sx={{ color: "#000" }}>
          Pogledajte i ostale proizvode koji bi vam se mogli svidjeti
        </SoftTypography>
      </SoftBox>
      <Divider sx={{ mb: "0" }} />

      <ScrollableProductList
        products={products.filter((p) => p.name !== "All-In-One set")}
        selectedCurrency={selectedCurrency}
        handleAddToCart={(product) => handleAdd(product, "suggestions_add_to_cart_button")}
      />

      <SoftBox display="flex" justifyContent="center" mt="4rem" />

      <Reviews productIds={[4,5,6]} />
    </PageLayout>
  );
};

export default AllInOneSet;
