import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";

import moment from "moment";
import localization from "moment/locale/bs";

import { SoftUIControllerProvider } from "context";

import AdminApp from "app/AdminApp";
import UserApp from "app/UserApp";

import "index.css";
import TagManager from "react-gtm-module";
import ErrorBoundary from "app/components/error-boundary/ErrorBoundary";

const tagManagerArgs = {
  gtmId: "GTM-5PHXQQ2G",
};

TagManager.initialize(tagManagerArgs);

const root = ReactDOM.createRoot(document.getElementById("root"));
moment.locale("bs", localization);

// Check subdomain
const getSubdomain = () => {
  const x = window.location.hostname.split(".")[0];
  return x === "localhost" ? "" : x;
};

// Render application
root.render(
  <BrowserRouter>
    <SoftUIControllerProvider>
      <ErrorBoundary>{getSubdomain() === "platform" ? <AdminApp /> : <UserApp />}</ErrorBoundary>
    </SoftUIControllerProvider>
  </BrowserRouter>
);
